<template>
  <vitae />
</template>

<script>
import Vitae from '@/components/Vitae.vue'

export default {
  name: 'ShowVitae',
  components: {
    Vitae
  }
}
</script>
