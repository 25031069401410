<template>
  <v-card class="mx-auto" outlined>
    <v-card-title>Lebenslauf</v-card-title>

    <v-list two-line>
      <template v-for="entry in entries">
      <v-list-item :key="entry">

        <v-list-item-content>
          <v-list-item-title>{{ entry.from }} - {{ entry.to }}</v-list-item-title>
          <v-list-item-subtitle>{{ entry.where }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-content>
          {{ entry.description }}
        </v-list-item-content>

      </v-list-item>
      <v-divider inset :key="entry"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'Resume',
  data () {
    return {
      entries: [
        {
          from: '2020',
          to: 'Gegenwärtig',
          title: 'Gegenwärtig',
          where: 'Luxemburg',
          description: 'Lebt und Arbeitet in Luxemburg'
        },
        {
          from: '2019',
          to: '2020',
          title: 'Masterstudiengang',
          where: 'Deutschland',
          description: 'Masterstudiengang für Bildende Kunst an der alanus Hochschule für Kunst und Gesellschaft, Alfter'
        },
        {
          from: '2018',
          to: 'Gegenwärtig',
          title: 'Beruf',
          where: 'Luxemburg',
          description: 'Kunstprofessorin im Centre éducatif de Dreiborn'
        },
        {
          from: '2012',
          to: '2017',
          title: 'Künstlerin',
          where: 'Luxemburg/Deutschland',
          description: 'Als Künstlerin zwischen Berlin und Luxemburg tätig'
        },
        {
          from: '2008',
          to: '2008',
          title: 'Bachelor',
          where: 'Deutschland',
          description: 'Bachelorstudiengang in der Klasse von andreas Orosz alanus Hochschule für Kunst und Gesellschaft, alfter'
        },
        {
          from: '2007',
          to: '2008',
          title: 'Privates',
          where: 'Weltweit',
          description: 'Weltreise'
        },
        {
          from: '2007',
          to: '2007',
          title: 'Abitur',
          where: 'Luxemburg',
          description: 'Abitur iBO (internationales baccalauréat) an der Waldorschule'
        },

      ]
    }
  }
}
</script>

<style>
</style>
