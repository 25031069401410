<template>
  <v-container fluid>
    <v-layout wrap class="ma-10">
      <v-flex xs12 sm12 md4>
        <contact-card />
      </v-flex>
      <v-flex xs12 sm12 md8>
        <resume />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ContactCard from './ContactCard.vue'
import Resume from './Resume.vue'
export default {
  name: 'Vitae',
  components: {
    ContactCard,
    Resume
  }
}
</script>

<style>
</style>
